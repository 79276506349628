.Logintext{
    font-size: 30px;
    margin-top: 10px;
    font-weight: bold;
}
.Logo{
  height:50px;
  margin-top:20px;
  border-radius: 40px;
}
.passwod{
    text-align:center;
    align-self: center;
height: 40px;
border-radius: 20px;
width: 250px;
position: absolute;
left: 50%;
transform: translateX(-50%);
margin-top: 10px;

    
}
.Loginbtn{
position: absolute;
width: 304px;
height: 103px;
left: 23px;
top: 550px;

background: #D9D9D9;
box-shadow: 8px 8px 16px 15px rgba(0, 0, 0, 0.24);
border-radius: 38px;
border: #D9D9D9;    
}
